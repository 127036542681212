import React from "react"
import { Link } from "gatsby"

export default () => (
  <div>
    <p>Hello Gatsby!</p>
    <Link to="/account">Go to your account</Link>
  </div>
)

